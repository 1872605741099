const HowDoWeDo = () => {
  return (
    <div className="d-flex flex-column" style={{ backgroundColor: "#4d6a00" }}>
      <div className="container">
        <img src="/images/6913-01.png" style={{ width: "100%" }} />
        <img src="/images/ataui-09.png" style={{ width: "100%" }} />
        <div
          className="col-md-9"
          style={{
            marginTop: 30,
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            color: "white",
            fontSize: 20,
          }}
        >
          As a boutique consulting firm, we work to provide maximum benefit to a
          limited number of clients. All of our clients are privileged to us and
          we are as passionately attached to all of our projects as they are. We
          act with the motivation of empathy and cooperation. We implement our
          projects with an experienced, professional and global team and a large
          pool of solution partners with the same qualifications.
        </div>
      </div>
    </div>
  );
};

export default HowDoWeDo;
