import { useEffect, useRef, useState } from "react";

const Statistics = (props) => {
  const {
    countryValue,
    solutionGroupValue,
    solutionPartnerValue,
    businessNetworkValue,
  } = props;
  const [countryCount, setCountryCount] = useState(0);
  const [solutionGroupCount, setSolutionGroupCount] = useState(0);
  const [solutionPartnerCount, setSolutionPartnerCount] = useState(0);
  const [businessNetworkCount, setBusinessNetworkCount] = useState(0);
  let currentCountryCount = 0;
  let currentSolutionGroupCount = 0;
  let countryGroupCountIntervalId = null;
  let solutionGroupCountIntervalId = null;
  let solutionPartnerCountIntervalId = null;
  let businessNetworkCountIntervalId = null;
  const ref = useRef(null);

  const startIncrementInterval = (
    intervalId,
    count,
    value,
    state,
    incrementValue
  ) => {
    intervalId = setInterval(() => {
      count = count + incrementValue;
      state(count);
      if (parseInt(count) === parseInt(value)) {
        clearInterval(intervalId);
      }
    }, [10]);
  };

  const handleIncrementCount = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      startIncrementInterval(
        countryGroupCountIntervalId,
        currentCountryCount,
        countryValue,
        setCountryCount,
        0.1
      );
      startIncrementInterval(
        solutionGroupCountIntervalId,
        currentSolutionGroupCount,
        solutionGroupValue,
        setSolutionGroupCount,
        0.1
      );
      startIncrementInterval(
        solutionPartnerCountIntervalId,
        solutionPartnerCount,
        solutionPartnerValue,
        setSolutionPartnerCount,
        0.2
      );
      startIncrementInterval(
        businessNetworkCountIntervalId,
        businessNetworkCount,
        businessNetworkValue,
        setBusinessNetworkCount,
        0.3
      );
    } else {
      if (countryGroupCountIntervalId) {
        clearInterval(countryGroupCountIntervalId);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIncrementCount, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundColor: "white" }}
      ref={ref}
    >
      <div
        className="d-flex flex-row flex-wrap justify-content-around align-items-center"
        style={{
          padding: 30,
          color: "#f74c00",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        <div className="col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-2">
            <div className="" style={{ fontSize: 30 }}>
              {parseInt(countryCount)}
            </div>
            <div className="" style={{ fontSize: 20 }}>
              COUNTRIES
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-2">
            <div className="" style={{ fontSize: 30 }}>
              {parseInt(solutionGroupCount)}
            </div>
            <div className="" style={{ fontSize: 20 }}>
              SOLUTION GROUPS
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-2">
            <div className="" style={{ fontSize: 30 }}>
              {parseInt(solutionPartnerCount)}+
            </div>
            <div className="" style={{ fontSize: 20 }}>
              SOLUTION PARTNERS
            </div>
          </div>
        </div>
        {/* <div className="col-md-3">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="" style={{ fontSize: 30 }}>
              {parseInt(businessNetworkCount)}+
            </div>
            <div className="" style={{ fontSize: 20 }}>
              BUSINESS NETWORK
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Statistics;
