const DiverseSolutions = () => {
  return (
    <div className="" style={{ paddingTop: 50 }}>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        // style={{ backgroundColor: "#4d6a00" }}
      >
        <div
          className="d-flex flex-row flex-wrap justify-content-center align-items-center"
          style={{
            textAlign: "center",
            paddingTop: 30,
            paddingBottom: 30,
            // color: "white",
          }}
        >
          <div style={{ fontSize: 28, marginRight: 10 }}>Diverse</div> solutions
          are
          <div style={{ fontSize: 28, marginLeft: 10, marginRight: 10 }}>
            professionally
          </div>
          executed by
          <div style={{ fontSize: 28, marginLeft: 10, marginRight: 10 }}>
            experienced
          </div>
          and
          <div style={{ fontSize: 28, marginLeft: 10, marginRight: 10 }}>
            global
          </div>
          experts
        </div>

        {/* <div
        className="d-flex flex-row flex-wrap justify-content-center align-items-center col-md-8"
        style={{
          marginTop: 50,
          marginBottom: 50,
          color: "white",
          textAlign: "center",
        }}
      >
        <div className="col-md-6" style={{ marginTop: 20, marginBottom: 20 }}>
          Professional
        </div>
        <div className="col-md-6" style={{ marginTop: 20, marginBottom: 20 }}>
          Experienced
        </div>
        <div className="col-md-6" style={{ marginTop: 20, marginBottom: 20 }}>
          Diverse
        </div>
        <div className="col-md-6" style={{ marginTop: 20, marginBottom: 20 }}>
          Global
        </div>
      </div> */}
        {/* <div
        className="col-md-12"
        style={{
          backgroundColor: "#aaaaaa",
          paddingBottom: 20,
          paddingTop: 20,
        }}
      >
        <div
          className="d-flex flex-row flex-wrap justify-content-center align-items-center col-md-8"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="col-md-3" style={{ textAlign: "center" }}>
            <div
              className=""
              style={{ color: "white", paddingLeft: 10, paddingRight: 10 }}
            >
              <div style={{ fontSize: 16 }}>Lorem Ipsum</div>
              <div className="" style={{ fontSize: 12, marginTop: 20 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                a nisi non ante posuere vulputate. Duis maximus diam nibh, a
                sollicitudin tellus vulputate non. Curabitur at aliquet risus.
                Phasellus tristique varius erat, eu efficitur ipsum blandit sit
                amet. Morbi in lacinia lacus.
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ textAlign: "center" }}>
            <div
              className=""
              style={{ color: "white", paddingLeft: 10, paddingRight: 10 }}
            >
              <div style={{ fontSize: 16 }}>Lorem Ipsum</div>
              <div className="" style={{ fontSize: 12, marginTop: 20 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                a nisi non ante posuere vulputate. Duis maximus diam nibh, a
                sollicitudin tellus vulputate non. Curabitur at aliquet risus.
                Phasellus tristique varius erat, eu efficitur ipsum blandit sit
                amet. Morbi in lacinia lacus.
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ textAlign: "center" }}>
            <div
              className=""
              style={{ color: "white", paddingLeft: 10, paddingRight: 10 }}
            >
              <div style={{ fontSize: 16 }}>Lorem Ipsum</div>
              <div className="" style={{ fontSize: 12, marginTop: 20 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                a nisi non ante posuere vulputate. Duis maximus diam nibh, a
                sollicitudin tellus vulputate non. Curabitur at aliquet risus.
                Phasellus tristique varius erat, eu efficitur ipsum blandit sit
                amet. Morbi in lacinia lacus.
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ textAlign: "center" }}>
            <div
              className=""
              style={{ color: "white", paddingLeft: 10, paddingRight: 10 }}
            >
              <div style={{ fontSize: 16 }}>Lorem Ipsum</div>
              <div className="" style={{ fontSize: 12, marginTop: 20 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                a nisi non ante posuere vulputate. Duis maximus diam nibh, a
                sollicitudin tellus vulputate non. Curabitur at aliquet risus.
                Phasellus tristique varius erat, eu efficitur ipsum blandit sit
                amet. Morbi in lacinia lacus.
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default DiverseSolutions;
