const WhatDoWeDo = () => {
  return (
    <div className="d-flex flex-column" style={{ backgroundColor: "#364203" }}>
      <div className="container">
        <img src="/images/6308-01.png" style={{ width: "100%" }} />
        <img src="/images/ataui-07.png" style={{ width: "100%" }} />
        <div
          className="col-md-9"
          style={{
            marginTop: 30,
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            color: "white",
            fontSize: 20,
          }}
        >
          We provide business consulting services for companies that want to
          operate in Germany, Switzerland and Turkey. Day by day, we are working
          to expand our field of activity without sacrificing service quality.
          Company establishment service, financial solutions, legal solutions,
          business development, project management, branding, marketing and
          software solutions are the main solutions we offer as Ata Consulting.
        </div>
      </div>
    </div>
  );
};

export default WhatDoWeDo;
