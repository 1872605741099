import axios from "axios";

export const createContactUsItem = async (contactUs) => {
  try {
    const response = await axios.post(
      "https://e43kj00h9j.execute-api.eu-west-1.amazonaws.com/dev/contact-us",
      contactUs
    );
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};
