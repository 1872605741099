import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingScreen from "./screens/LandingScreen";
import ServicesScreen from "./screens/ServicesScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import SingleServiceScreen from "./screens/SingleServiceScreen";
import serviceList from "./data/services.json";
import Navbar from "./components/navigation/Navbar";
import ContactUsScreen from "./screens/ContactUsScreen";
import Footer from "./components/navigation/Footer";
import { ReactNotifications } from "react-notifications-component";

function App() {
  return (
    <React.Fragment>
      <ReactNotifications />
      <Navbar />
      <Routes>
        {serviceList.map((service, serviceIndex) => (
          <Route
            key={`single_service_${serviceIndex}`}
            path={`/services/${service.path}`}
            element={<SingleServiceScreen service={service} />}
          />
        ))}
        <Route key="services" path="/services" element={<ServicesScreen />} />
        <Route key="aboutus" path="/about-us" element={<AboutUsScreen />} />
        <Route
          key="contactus"
          path="/contact-us"
          element={<ContactUsScreen />}
        />
        <Route key="landing" path="/" element={<LandingScreen />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
  // return <AboutUsScreen />;
  // return <ServicesScreen />;
  // return <LandingScreen />;
}

export default App;
