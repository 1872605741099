import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";

const Footer = () => {
  return (
    <div
      className=""
      style={{ backgroundColor: "#f2f2f2", borderTop: "1px solid" }}
    >
      <div className="container">
        <div
          className="d-flex flex-row flex-wrap justify-content-start align-items-start"
          style={{ padding: 20 }}
        >
          <div className="col-md-4">
            <div className="d-flex flex-column" style={{ padding: 20 }}>
              <div className="" style={{ fontWeight: "bold", fontSize: 24 }}>
                CONTACT US
              </div>
              {/* <a
                className="footer-item"
                href="https://www.google.com/maps?z=16&q=kavakl%C4%B1dere%2Bmah.,%2Batat%C3%BCrk%2Bbul.,%2Bno:185/5%2B%C3%87ankaya,%2Bankara"
              >
                Kavaklıdere Mah., Atatürk Bul., No:185/5 Çankaya, Ankara
              </a> */}
              {/* <a className="footer-item" href="tel:+903126665405">
                +90 (312) 666 54 05
              </a> */}
              <a
                className="footer-item"
                href="mailto:info@ataconsultingglobal.com"
              >
                info@ataconsultingglobal.com
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column" style={{ padding: 20 }}>
              <div className="" style={{ fontWeight: "bold", fontSize: 24 }}>
                FOLLOW US
              </div>
              <div className="d-flex flex-row flex-wrap">
                <a
                  href="https://www.instagram.com/ataconsulting.global/"
                  target="_blank"
                >
                  <FiInstagram
                    size={30}
                    color={"#f74c00"}
                    style={{
                      cursor: "pointer",
                      margin: 10,
                      // boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
                    }}
                  />
                </a>
                {/* <FiFacebook
                  size={30}
                  color={"#f74c00"}
                  style={{
                    cursor: "pointer",
                    margin: 10,
                  }}
                />
                <FiTwitter
                  size={30}
                  color={"#f74c00"}
                  style={{
                    cursor: "pointer",
                    margin: 10,
                  }}
                /> */}
                <a
                  href="https://www.linkedin.com/company/ata-consulting-global"
                  target="_blank"
                >
                  <FiLinkedin
                    color={"#f74c00"}
                    size={30}
                    style={{ cursor: "pointer", margin: 10 }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column" style={{ padding: 20 }}>
              <div className="" style={{ fontWeight: "bold", fontSize: 24 }}>
                GOOGLE MAPS
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
