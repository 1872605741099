const ServiceItemLeft = (props) => {
  const { imageSrc, title, description, subHeaderList, handleOnClick } = props;
  return (
    <div
      className="d-flex flex-column"
      style={{ borderTop: "2px solid #cccccc", marginTop: 10 }}
    >
      <div className="d-flex flex-row flex-wrap align-items-center">
        <div className="col-md-8">
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-8">
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    color: "#f74c00",
                    fontSize: 24,
                    fontWeight: "bold",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {title}
                </div>
                <div className="" style={{ marginTop: 10, marginBottom: 10 }}>
                  {description.map((descriptionItem, descriptionItemIndex) => (
                    <div
                      key={descriptionItemIndex}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      {descriptionItem}
                    </div>
                  ))}
                </div>

                {subHeaderList
                  ? subHeaderList.map((subHeader, subHeaderIndex) => (
                      <div key={subHeaderIndex} style={{ padding: 20 }}>
                        <div style={{ fontWeight: "bold" }}>
                          {subHeader.title}
                        </div>
                        <div style={{ marginTop: 10 }}>
                          {subHeader.description}
                        </div>
                      </div>
                    ))
                  : null}
                <div className="d-flex mt-5">
                  <button className="primary-button" onClick={handleOnClick}>
                    CONTACT US
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex justify-content-center align-items-center">
            <img src={imageSrc} width={"75%"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItemLeft;
