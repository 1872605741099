import {
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiPhoneIncoming,
  FiCheckCircle,
} from "react-icons/fi";
import { IoMapSharp } from "react-icons/io5";
import { createContactUsItem } from "../../requests/ContactUsRequests";
import { Store } from "react-notifications-component";
import { useState } from "react";

const ContactUs = () => {
  const [pageState, setPageState] = useState(0);
  const [sending, setSending] = useState(false);
  const handleCreateContactUsItem = async () => {
    // const address = document.getElementById("address").value.trim();
    const email = document.getElementById("email").value.trim();
    // const phoneNumber = document.getElementById("phone-number").value.trim();
    const firstName = document.getElementById("first-name").value.trim();
    const lastName = document.getElementById("last-name").value.trim();
    const companyName = document.getElementById("company-name").value.trim();
    const tellUsMore = document.getElementById("tell-us-more").value.trim();
    if (email && firstName && lastName && tellUsMore) {
      const contactUs = {
        id: new Date().getTime(),
        email,
        // phoneNumber,
        firstName,
        lastName,
        companyName,
        tellUsMore,
      };
      setSending(true);
      const response = await createContactUsItem(contactUs);
      if (response) {
        setPageState(1);
        setSending(false);
      }
    } else {
      Store.addNotification({
        title: "Problem!",
        message: "Please fill the required fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setSending(false);
    }
  };

  const RenderMainContent = () => {
    if (pageState === 0) {
      return (
        <div className="col-md-6 p-3">
          <div className="input-base">
            <label className="input-header">
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <input id="first-name" type={"text"} className="input-value" />
          </div>
          <div className="input-base">
            <label className="input-header">
              Last Name <span style={{ color: "red" }}>*</span>
            </label>
            <input id="last-name" type={"text"} className="input-value" />
          </div>
          <div className="input-base">
            <label className="input-header">
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input id="email" type={"text"} className="input-value" />
          </div>
          {/* <div className="input-base">
            <label className="input-header">
              Mobile Phone Number <span style={{ color: "red" }}>*</span>{" "}
            </label>
            <label
              className="input-header"
              style={{ fontStyle: "italic", fontWeight: "normal" }}
            >
              (including area code)
            </label>
            <input id="phone-number" type={"text"} className="input-value" />
          </div> */}

          <div className="input-base">
            <label className="input-header">Company Name</label>
            <input id="company-name" type={"text"} className="input-value" />
          </div>
          <div className="input-base">
            <label className="input-header">
              Tell Us More <span style={{ color: "red" }}>*</span>
            </label>
            <textarea id="tell-us-more" className="input-value" />
          </div>
          {sending ? null : (
            <div className="d-flex flex-column mt-3">
              <button
                className="primary-button"
                onClick={handleCreateContactUsItem}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      );
    } else if (pageState === 1) {
      return (
        <div className="col-md-6 p-3">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <FiCheckCircle size={150} color="green" />
            <div
              style={{ marginTop: 20, fontWeight: "bold", textAlign: "center" }}
            >
              Thanks for contacting us. We have received your information and
              you will hear from us as soon as possible.
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-8" style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
          <div className="col-md-6 p-3">
            <div
              style={{ fontSize: 30, textAlign: "center", fontWeight: "bold" }}
            >
              Let us inform you about the most suitable solution for your brand!
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center m-3">
              <a
                href="https://www.instagram.com/ataconsulting.global/"
                target="_blank"
              >
                <FiInstagram
                  size={40}
                  // color={"#C13584"}
                  color={"#f74c00"}
                  style={{ cursor: "pointer", margin: 10 }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ata-consulting-global"
                target="_blank"
              >
                <FiLinkedin
                  size={40}
                  // color={"#0072b1"}
                  color={"#f74c00"}
                  style={{ cursor: "pointer", margin: 10 }}
                />
              </a>
            </div>

            <div className="d-flex flex-row flex-wrap justify-content-center">
              <div className="col-md-6 mt-3">
                <a
                  href="mailto:info@ataconsultingglobal.com"
                  className="footer-item"
                >
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <FiMail size={70} color={"#f74c00"} />
                    <div className="mt-2">info@ataconsultingglobal.com</div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 mt-3">
                <a href="tel:+903126665405" className="footer-item">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <FiPhoneIncoming size={70} color={"#f74c00"} />
                    {/* <div className="mt-2">+90 (312) 666 54 05</div> */}
                  </div>
                </a>
              </div>
              <div className="col-md-12 mt-3">
                <a
                  href="https://www.google.com/maps?z=16&q=kavakl%C4%B1dere%2Bmah.,%2Batat%C3%BCrk%2Bbul.,%2Bno:185/5%2B%C3%87ankaya,%2Bankara"
                  className="footer-item"
                >
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <IoMapSharp size={70} color={"#f74c00"} />
                    {/* <div className="mt-2 text-center">
                      Kavaklıdere Mah., Atatürk Bul., No:185/5, Çankaya, Ankara
                      (Please contact us in advance, our business model is based
                      on remote working)
                    </div> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
          {RenderMainContent()}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
