import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div
      className=""
      style={{
        // backgroundColor: "#f2f2f2",
        backgroundColor: "#fff",
        borderBottom: "1px solid lightgray",
      }}
    >
      <div className="container">
        <nav
          className="navbar navbar-expand-lg"
          style={{
            color: "#364203",
          }}
        >
          <a className="navbar-brand">
            <img
              src="/images/logo_full.png"
              style={{
                marginLeft: 10,
                marginRight: 10,
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
                height: 80,
                // boxShadow:
                //   "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                // boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <AiOutlineMenu size={30} />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav" style={{ marginLeft: "auto" }}>
              <li className="nav-item">
                <a
                  className="nav-link header-item"
                  onClick={() => {
                    navigate("/services");
                  }}
                >
                  What We Do?
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link header-item"
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  Who We Are?
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link header-item"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
