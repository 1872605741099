const Purpose = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ marginTop: 50, marginBottom: 50 }}
    >
      <div
        style={{
          textAlign: "center",
          color: "#364203",
          fontSize: 30,
          marginBottom: 50,
          fontStyle: "italic",
          textDecoration: "underline",
        }}
      >
        WE ARE ATA CONSULTING!
      </div>
      <div
        className="col-md-6"
        style={{
          marginTop: 30,
          marginBottom: 50,
          textAlign: "center",
          color: "#364203",
          fontSize: 24,
          padding: 20,
          borderRadius: 10,
          boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
        }}
      >
        An <span style={{ fontWeight: "bold" }}>exclusive</span>,{" "}
        <span style={{ fontWeight: "bold" }}>dynamic</span> and{" "}
        <span style={{ fontWeight: "bold" }}>boutique</span> consulting firm
        with <span style={{ fontWeight: "bold" }}>quality service</span> and{" "}
        <span style={{ fontWeight: "bold" }}>limited active customers</span>{" "}
        that puts{" "}
        <span style={{ fontWeight: "bold" }}>customer satisfaction</span> at the
        center
      </div>
    </div>
  );
};

export default Purpose;
