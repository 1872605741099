import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DiverseSolutions from "../components/landing/DiverseSolutions";
import HowDoWeDo from "../components/landing/HowDoWeDo";
import MainBanner from "../components/banners/MainBanner";
import Purpose from "../components/landing/Purpose";
import WhatDoWeDo from "../components/landing/WhatDoWeDo";
import WhereWeAre from "../components/landing/WhereWeAre";
import WantToLearnMore from "../components/services/WantToLearnMore";

const LandingScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleWantToLearnMoreButtonOnPress = () => {
    navigate("/services");
  };
  return (
    <div className="">
      <MainBanner />
      <Purpose />
      <WhatDoWeDo />
      <HowDoWeDo />
      <WhereWeAre />
      <DiverseSolutions />
      <WantToLearnMore
        headerText="Take a look at our services"
        headerDescription="Company Formation | Legal Solutions | Financial Solutions | Business Development | Project Management | Branding Solutions | Marketing Solutions | Software Solutions"
        buttonTitle="SERVICES"
        handleOnPress={handleWantToLearnMoreButtonOnPress}
      />
      {/* <TakeALookAtServices /> */}
    </div>
  );
};

export default LandingScreen;
