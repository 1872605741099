const WhereWeAre = () => {
  return (
    <div className="d-flex flex-column" style={{ backgroundColor: "#364203" }}>
      <div className="container">
        <img
          src="/images/2979593_Mesa de trabajo 1.png"
          style={{ width: "100%" }}
        />
        <img src="/images/ataui-08.png" style={{ width: "100%" }} />
        <div
          className="col-md-9"
          style={{
            marginTop: 30,
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            fontSize: 20,
            color: "white",
          }}
        >
          Our headquarters are located in Switzerland. We currently carry out
          our operations in Germany, Switzerland and Turkey. We provide support
          in German, English and Turkish, you can contact us from anywhere in
          the world!
        </div>
      </div>
    </div>
  );
};

export default WhereWeAre;
