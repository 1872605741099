const AboutUsHeader = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-9">
        <div
          className=""
          style={{
            textAlign: "center",
            marginTop: 50,
            marginBottom: 30,
            color: "#364203",
            fontSize: 30,
            fontStyle: "italic",
            textDecoration: "underline",
          }}
        >
          OUR VALUES DEFINE WHO WE ARE!
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeader;
