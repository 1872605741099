const WantToLearnMore = (props) => {
  const { headerText, headerDescription, buttonTitle, handleOnPress } = props;

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        marginTop: 50,
        backgroundImage: "url('/images/Untitled-4-01.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        height: 600,
        color: "white",
        borderRadius: 10,
      }}
    >
      <div className="d-flex flex-column col-md-5">
        <div style={{ fontSize: 30, textAlign: "center" }}>{headerText}</div>
        <div
          style={{
            marginTop: 20,
            fontSize: 20,
            textAlign: "center",
            fontStyle: "italic",
          }}
        >
          {headerDescription}
        </div>
        <button
          className="primary-button"
          style={{ marginTop: 30 }}
          onClick={handleOnPress}
        >
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default WantToLearnMore;
