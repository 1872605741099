import { useEffect } from "react";
import ContactUs from "../components/landing/ContactUs";
import MainBanner from "../components/banners/MainBanner";

const ContactUsScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <div className="page-header">
        <span style={{ borderBottom: "1px solid" }}>CONTACT US</span>
      </div>
      <MainBanner />
      <ContactUs />
    </div>
  );
};

export default ContactUsScreen;
