import { VscDebugBreakpointData } from "react-icons/vsc";

const SuccessFormulation = () => {
  return (
    <div
      className=""
      style={{
        position: "relative",
        backgroundImage: "url('/images/Untitled-4-01 copy.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="d-flex flex-row">
        <div className="d-flex flex-column justify-content-center align-items-center col-md-6 p-3">
          <div
            className=""
            style={{
              color: "white",
              marginTop: 40,
              marginBottom: 40,
              textAlign: "center",
              fontSize: 30,
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            AIM
          </div>

          <div className="d-flex justify-content-center">
            <VscDebugBreakpointData size={30} color={"white"} />
          </div>

          <div
            className=""
            style={{
              color: "white",
              marginTop: 40,
              marginBottom: 40,
              textAlign: "center",
              fontSize: 30,
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            FOCUS
          </div>
          <div className="d-flex justify-content-center">
            <VscDebugBreakpointData size={30} color={"white"} />
          </div>
          <div
            className=""
            style={{
              color: "white",
              marginTop: 40,
              marginBottom: 40,
              textAlign: "center",
              fontSize: 30,
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            ACHIEVE
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center col-md-6">
          <img src="/images/atauih-03.png" width={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default SuccessFormulation;
