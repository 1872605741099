import { useEffect } from "react";
import MainBanner from "../components/banners/MainBanner";
import PeopleBanner from "../components/banners/PeopleBanner";
import ServicesHeader from "../components/services/ServicesHeader";
import ServiceItemRight from "../components/services/ServiceItemRight";
import ServiceItemLeft from "../components/services/ServiceItemLeft";
import TrippleThickDivider from "../components/divider/TrippleThickDivider";
import ContactUs from "../components/landing/ContactUs";
import MainBannerWithTitle from "../components/banners/MainBannerWithTitle";

const ServicesScreen = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <div className="page-header">
        <span style={{ borderBottom: "1px solid" }}>SERVICES</span>
      </div>
      <MainBanner />
      <ServicesHeader />
      <div className="container">
        <ServiceItemRight
          imageSrc={"/images/Untitled-1-04.png"}
          title={"Company Formation"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "Mainly for companies that set up their branch or subsidiary in Switzerland or Germany, or vice versa for companies from Switzerland and Germany that want to establish themselves in the Turkish market, the founding formalities are taken over and processed together with our partner law firms.",
            "The first kick-off meeting with the customer takes into account the customer's wishes in terms of the right legal form, people involved, start-up capital, liability, external appearance and company name. After recording and defining these points, the further steps such as preparing the documents/statutes (partnership agreement), depositing the capital (opening a business account), notary appointment (public certification of the documents) and finally the entry in the commercial register are carried out.",
            "Partner law firms for the respective country of incorporation are always involved in the execution of the incorporation and are also at your side for other legal advice.",
          ]}
        />
        <ServiceItemLeft
          imageSrc={"/images/Untitled-1-01 copy.png"}
          title={"Legal Solutions"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "Solid legal support means a solid company. Your company should both be able to solve existing legal problems and proactively prevent legal problems that it may encounter in the future. We work with an experienced and dedicated team of German and Swiss legislation. We take over your legal processes end-to-end, so you can confidently step into the future and focus on growing your company quickly.",
          ]}
        />
        <ServiceItemRight
          imageSrc={"/images/Untitled-1-02.png"}
          title={"Financial Solutions"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[]}
          subHeaderList={[
            {
              title: "Financial Advisory in Switzerland & Germany",
              description:
                "The increasing competitive environment of business life and constantly changing legal regulations have made it mandatory for companies to manage their financial affairs processes (accounting, tax, financial reporting, payroll, etc.) in the most efficient and correct way. As Ata Consulting, we help our taxpayers to prepare their tax and accounting functions for the future in a rapidly changing world. While presenting our services to our customers, our approach is to work in full cooperation with our customers in a flexible structure, to provide services that meet their changing needs and to support their growth by adding value to our customers.",
            },
            {
              title: "Credit Facilitation in Switzerland & Germany",
              description:
                "If you want to set up a company in Switzerland or Germany, or if you already have one, and you want your company to grow and strengthen, a loan may be a logical option. We manage your company's credit processes under credit facilitation services. We find the most suitable loan solution for you, and we manage and accelerate the processes. Your business is wasting no time to grow!",
            },
          ]}
        />
        <ServiceItemLeft
          imageSrc={"/images/ataSui-07.png"}
          title={"Business Development"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "As Ata consulting, we provide support for your business development activities. We work with you with the same dedication and support you in growing your business in many processes such as company establishment, market entry processes, marketing, creating sales channels and finding strategic partners.",
          ]}
          subHeaderList={[
            {
              title: "Trade Missions",
              description:
                "Let's carry out the activities you will do together or let us, as Ata Consulting, do it for you. These activities can range from conducting on-site interviews, to finding a solution partner, and even to market research services.",
            },
            {
              title: "Local Representation",
              description:
                "Let us represent your company in the regions where we operate within the framework of your needs. Let us be a communication channel in the field for your products, services and brand. Let's speed up your customer and strategic partner finding processes.",
            },
            {
              title: "Event Organization",
              description:
                "For the fairs, symposiums, interviews or meetings you want to attend or organize, we organize the entire organization for you from end to end.",
            },
            {
              title: "B2B Match-Making",
              description:
                "We bring you together with businesses and institutions that are in the target customer segment of your business, and businesses and institutions that you want to strategically cooperate with.",
            },
          ]}
        />
        <ServiceItemRight
          imageSrc={"/images/ataSui-05.png"}
          title={"Project Management"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "Let us manage the projects that need to be implemented for your business and bring them to life for you. These projects can vary from changing the interior architecture of your business to what needs to be done in the organizational chart of your business.",
          ]}
        />
        <ServiceItemLeft
          imageSrc={"/images/Untitled-1-03.png"}
          title={"Branding Solutions"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "Your brand is your foundation, your strength, your gasoline. Brands represent a particular strength of any kind. Do not be afraid to unleash this power. Sincere brands are brands that unwittingly leave a mark on people. Combine your brand with your passion and achieve intimacy. The purpose of your brand identity is to create awareness, attract attention and increase sales.",
            "A solid brand has the capacity to market itself. Corporate organizations have a permanent and diverse visual identity. If all elements of your brand act in harmony, it will increase your prestige and leave a pleasant feeling to you and your customers. The work we do while creating your brand; sector analysis, competitor identification, target audience analysis, brand identity, brand story, logo, visual design and corporate identity studies.",
          ]}
        />
        <ServiceItemRight
          imageSrc={"/images/Untitled-1-05.png"}
          title={"Marketing Solutions"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "First, we analyze your competitors in more detail, perform a detailed customer segmentation, identify our target customer segments, and conduct a value positioning study for these customer types. At the end of this process, we create your brand's 'Marketing Mix' and your marketing strategy is thus shaped. We integrate the most suitable solution partners into the operation and now we manage all your marketing operations for you.",
          ]}
        />
        <ServiceItemLeft
          imageSrc={"/images/ataSui-08.png"}
          title={"Software Solutions"}
          handleOnClick={() => {
            window.open("/contact-us", "_blank", "noopener,noreferrer");
          }}
          description={[
            "The place of information technologies is very important in the changing business world with the effect of technology. If your company and brands do not have a digital infrastructure and digital identities, creating this will be a pleasurable process for you and us. We offer new or complementary solutions in the software field. We solve your problems with a wide pool of solutions such as mobile application development, web development, personalized software development, UI / UX design, DevOps services, solution architecture, cloud solutions, data science, application maintenance and support services.",
          ]}
        />
      </div>
    </div>
  );
};

export default ServicesScreen;
