const AboutUsItem = (props) => {
  const { title, description, backgroundColor } = props;
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        backgroundColor,
        marginTop: 25,
        marginBottom: 25,
        paddingTop: 25,
        paddingBottom: 25,
        borderRadius: 5,
        boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
      }}
    >
      <div
        className=""
        style={{ fontSize: 30, color: "#f74c00", fontWeight: "bold" }}
      >
        {title}
      </div>
      <div
        className="col-md-9"
        style={{
          marginTop: 20,
          fontSize: 20,
          textAlign: "center",
          color: "white",
        }}
      >
        {description}
      </div>
    </div>
  );
};

export default AboutUsItem;
