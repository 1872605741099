const ServicesHeader = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-9">
        {/* <div
          className=""
          style={{
            textAlign: "center",
            marginTop: 50,
            color: "#f74c00",
            fontSize: 30,
          }}
        >
          Take a look at our services
        </div> */}
        <div
          className=""
          style={{
            textAlign: "center",
            marginTop: 50,
            marginBottom: 50,
            color: "#364203",
            fontSize: 30,
            fontStyle: "italic",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          THIS IS WHERE THE MAGIC HAPPENS
        </div>
      </div>
    </div>
  );
};

export default ServicesHeader;
