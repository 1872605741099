import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainBanner from "../components/banners/MainBanner";
import WantToLearnMore from "../components/services/WantToLearnMore";

const SingleServiceScreen = (props) => {
  const { service } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleWantToLearnMoreButtonOnPress = () => {
    navigate("/contact-us");
  };

  const RenderServicePropList = () => {
    if (service && service.elementList) {
      return service.elementList.map((element) => {
        if (element.type === "TEXT") {
          return (
            <div className="" style={{ marginTop: 20 }}>
              {element.value}
            </div>
          );
        }
      });
    }
    return null;
  };
  return (
    <div className="">
      <MainBanner />
      <div className="container">
        <div className="" style={{ marginTop: 50, marginBottom: 50 }}>
          <div
            className=""
            style={{ fontSize: 30, fontWeight: "bold", color: "#f74c00" }}
          >
            {service.title}
          </div>
          {RenderServicePropList()}

          <WantToLearnMore
            headerText="Would you like to know more?"
            headerDescription="If you have any quetions, please don't hesitate to react out to us! Our team of experts will get in touch with you soon"
            buttonTitle="CONTACT US"
            handleOnPress={handleWantToLearnMoreButtonOnPress}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleServiceScreen;
