import { useEffect } from "react";
import SuccessFormulation from "../components/aboutus/SuccessFormulation";
import FullLogo from "../components/aboutus/FullLogo";
import MainBanner from "../components/banners/MainBanner";
import AboutUsHeader from "../components/aboutus/AboutUsHeader";
import AboutUsItem from "../components/aboutus/AboutUsItem";
import Statistics from "../components/aboutus/Statistics";
import { useNavigate } from "react-router-dom";
import WantToLearnMore from "../components/services/WantToLearnMore";

const AboutUsScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleWantToLearnMoreButtonOnPress = () => {
    navigate("/services");
  };
  return (
    <div className="">
      <div className="page-header">
        <span style={{ borderBottom: "1px solid" }}>ABOUT US</span>
      </div>
      <MainBanner />
      {/* <FullLogo /> */}

      <Statistics
        countryValue={3}
        solutionGroupValue={8}
        solutionPartnerValue={20}
        businessNetworkValue={50}
      />
      <SuccessFormulation />
      <div className="container">
        <AboutUsHeader />
        <AboutUsItem
          title="PASSION"
          description="We are an organization that achieves everything with PASSION. This gives us a reason to love what we do."
          backgroundColor={"#364203"}
        />
        <AboutUsItem
          title="RELIABILITY"
          description="On the other hand, RELIABILITY stands as a reason for you to love what we do."
          backgroundColor={"#4d6a00"}
        />
        <AboutUsItem
          title="CO-OPERATION"
          description="We aim to deliver the promised services in a consistent and accurate manner, since we put customer satisfaction first. As we do it, we always try our best to bring best pieces together in order to create a high-quality outcome. Therefore, we have a collective structure, and we aim our focus on CO-OPERATION."
          backgroundColor={"#4c4c4c"}
        />
        <AboutUsItem
          title="DYNAMISM"
          description="Our goal is to deliver exclusive customer solutions provided by an all time active team and this makes us DYNAMIC."
          backgroundColor={"#7e7e7e"}
        />
        <AboutUsItem
          title="EMPATHY"
          description="We aim to be successful with these quality but a real successful business environment is a combination of respect and understanding towards co-workers and clients. That's why we assimilate EMPATHY."
          backgroundColor={"#c6c6c6"}
        />
      </div>
      <WantToLearnMore
        headerText="Take a look at our services"
        headerDescription="Company Formation | Legal Solutions | Financial Solutions | Business Development | Project Management | Branding Solutions | Marketing Solutions | Software Solutions."
        buttonTitle="SERVICES"
        handleOnPress={handleWantToLearnMoreButtonOnPress}
      />
    </div>
  );
};

export default AboutUsScreen;
